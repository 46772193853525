/* eslint-disable react/no-array-index-key */
import React, {FC, memo} from "react";
import {Typography} from "antd";
import css from "./TextBlock.less";
import Title from "../Title/Title";
// import i18n from "../../services/i18n";
import {formattedText} from "../../helpers/formati18nText";

type TextBlockProps = {
    width?: number;
    paddingTop?: number;
    title: string;
    text: Array<string>;
    alignment?: "center" | "left" | "right";
    children?: React.ReactNode;
};

const TextBlock: FC<TextBlockProps> = ({
    width = 1000,
    paddingTop = 30,
    title = "Title",
    text = ["Paragraph 1", "Paragraph 2"],
    alignment = "left",
    children,
}) => {
    const contentWidth = width;

    return (
        <div
            className={css.root}
            style={{
                paddingTop,
                textAlign: alignment,
            }}
        >
            <Title className={css.title} level={1}>
                {title}
            </Title>
            <Typography.Text className={css.textBox}>
                {text.map((paragraph, dataIndex) => {
                    return paragraph.includes("%%") ? (
                        <Typography.Paragraph
                            key={dataIndex}
                            className={css.highlighted}
                        >
                            {paragraph.split("%%").join("")}
                        </Typography.Paragraph>
                    ) : (
                        <Typography.Paragraph key={dataIndex} type="secondary">
                            <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    __html: formattedText(paragraph),
                                }}
                            />
                        </Typography.Paragraph>
                    );
                })}
                {children}
            </Typography.Text>
        </div>
    );
};

export default memo(TextBlock);
